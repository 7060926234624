import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/datamigrators.github.io/datamigrators.github.io/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`This page describes Data Migrators’ policy on server security.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Overview</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Purpose</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Scope</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy Compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Exceptions</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Non-compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Related Documents</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Definitions and Terms</AnchorLink>
    </AnchorLinks>
    <h2>{`Overview`}</h2>
    <p>{`Unsecured and vulnerable servers continue to be a major entry point for
malicious threat actors. Consistent Server installation policies,
ownership and configuration management are all about doing the basics
well.`}</p>
    <h2>{`Purpose`}</h2>
    <p>{`The purpose of this policy is to establish standards for the base
configuration of internal server equipment that is owned and/or operated
by Data Migrators. Effective implementation of this policy will
minimize unauthorized access to Data Migrators proprietary information
and technology.`}</p>
    <h2>{`Scope`}</h2>
    <p>{`All employees, contractors, consultants, temporary and other workers at
Cisco and its subsidiaries must adhere to this policy. This policy
applies to server equipment that is owned, operated, or leased by Cisco
or registered under a Cisco-owned internal network domain.`}</p>
    <p>{`This policy specifies requirements for equipment on the internal Cisco
network. For secure configuration of equipment external to Cisco on the
DMZ, see the Internet `}<em parentName="p">{`DMZ Equipment Policy`}</em>{`.`}</p>
    <h2>{`Policy`}</h2>
    <h3>{`General Requirements`}</h3>
    <p>{`All internal servers deployed at Data Migrators must be
owned by an operational group that is responsible for system
administration. Approved server configuration guides must be
established and maintained by each operational group, based
on business needs and approved by InfoSec. Operational
groups should monitor configuration compliance and implement
an exception policy tailored to their environment. Each
operational group must establish a process for changing the
configuration guides, which includes review and approval by
InfoSec. The following items must be met:`}</p>
    <p>{`Servers must be registered within the corporate enterprise
management system. At a minimum, the following information is
required to positively identify the point of contact:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Server contact(s) and location, and a backup contact`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Hardware and Operating System/Version`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Main functions and applications, if applicable`}</p>
      </li>
    </ul>
    <p>{`Information in the corporate enterprise management system must be
kept up-to-date.`}</p>
    <p>{`Configuration changes for production servers must follow the
appropriate change management procedures.`}</p>
    <p>{`For security, compliance, and maintenance purposes, authorized
personnel may monitor and audit equipment, systems, processes,
and network traffic per the `}<em parentName="p">{`Audit Policy`}</em>{`.`}</p>
    <h3>{`Configuration Requirements`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Operating System configuration should be in accordance with
approved InfoSec guidelines.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Services and applications that will not be used must be
disabled where practical.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Access to services should be logged and/or protected through
access-control methods such as a web application firewall,
if possible.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The most recent security patches must be installed on the
system as soon as practical, the only exception being when
immediate application would interfere with business
requirements.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Trust relationships between systems are a security risk, and
their use should be avoided. Do not use a trust relationship
when some other method of communication is sufficient.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Always use standard security principles of least required
access to perform a function. Do not use root when a
non-privileged account will do.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`If a methodology for secure channel connection is available
(i.e., technically feasible), privileged access must be
performed over secure channels, (e.g., encrypted network
connections using SSH or IPSec).`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Servers should be physically located in an access-controlled
environment.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Servers are specifically prohibited from operating from
uncontrolled cubicle areas.`}</p>
      </li>
    </ol>
    <h3>{`Monitoring`}</h3>
    <p>{`All security-related events on critical or sensitive systems
must be logged and audit trails saved as follows:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`All security related logs will be kept online for a minimum of 1
week.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Daily incremental tape backups will be retained for at least 1
month.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Weekly full tape backups of logs will be retained for at least 1
month.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Monthly full backups will be retained for a minimum of 2 years.`}</p>
      </li>
    </ul>
    <p>{`Security-related events will be reported to InfoSec, who will
review logs and report incidents to IT management. Corrective
measures will be prescribed as needed. Security-related events
include, but are not limited to:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Port-scan attacks`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Evidence of unauthorized access to privileged accounts`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Anomalous occurrences that are not related to specific applications
on the host.`}</p>
      </li>
    </ul>
    <h2>{`Policy Compliance`}</h2>
    <h3>{`Compliance Measurement`}</h3>
    <p>{`The Infosec team will verify compliance to this policy through various
methods, including but not limited to, periodic walk-thrus, video
monitoring, business tool reports, internal and external audits, and
feedback to the policy owner.`}</p>
    <h2>{`Exceptions`}</h2>
    <p>{`Any exception to the policy must be approved by the Infosec team in
advance.`}</p>
    <h2>{`Non-Compliance`}</h2>
    <p>{`An employee found to have violated this policy may be subject to
disciplinary action, up to and including termination of employment.`}</p>
    <h2>{`Related Documents`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "server_audit_policy"
          }}>{`Server Audit Policy`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "technology_equipment_disposal_policy"
          }}>{`Technology  Equipment Disposal Policy`}</a></p>
      </li>
    </ul>
    <h2>{`Definitions and Terms`}</h2>
    <p>{`The following definition and terms can be found in the `}<a parentName="p" {...{
        "href": "https://www.sans.org/security-resources/glossary-of-terms/"
      }}>{`SANS Glossary`}</a>{`:`}</p>
    <ul>
      <li parentName="ul">{`De-militarized zone (DMZ)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      